.artist-profile {
    cursor: pointer;
    img {
        width: 100%;
        object-fit: cover;
        transition: .3s;
        &:hover {
            opacity: .7;
        }
    }
}


@media (min-width:210px) and (max-width:767px) {
    .artist-profile1 img {
        width: 100px;
        height: 100px;
    }
}