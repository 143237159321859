@import "./common.scss";
@import "./variable.scss";
@import "./sidebar.scss";
@import "../Layout/layout.scss";
@import "./media.scss";

body {
  font-family: "Nunito", sans-serif;
  background-color: #111f1e;
}
@font-face {
  font-family: brand-font;
  src: url("../../Assets/Font/brandishregular.ttf");
}
.heading-font {
  font-family: brand-font !important;
}
.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
input:focus {
  outline: none;
}
button:focus {
  outline: none;
}

.main-box {
  padding-bottom: 15px; 
  position: overflow;
  &.mobile-Box {
    padding-bottom: 120px;
  }
}
.z-index-1 {
  z-index: 1;
}
.play-table1 {
  max-width: calc(100vw - 240px);
  overflow: auto;
  // height: calc(100vh - 585px);
}
.play-table1 .play-table-details1 th,
.play-table1 .play-table-details1 td {
  padding: 0.85rem;
  border-right: 1px solid var(--table-border);
  border-bottom: 1px solid var(--table-border);
}
.play-table-details1 td:nth-child(1) {
  min-width: 350px;
}
.play-table-details1 td:nth-child(2) {
  min-width: 350px;
}
.play-table-details1 td:nth-child(3) {
  min-width: calc(100vw - 965px);
}
.artist-profile1 {
  img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.box-shadow-cus {
  box-shadow: (0px 0px 25px rgba(0, 0, 0, 0.47));
}

@media (min-width: 1025px) and (max-width: 1500px) {
  .modal-login-box {
    height: calc(100vh - 30px) !important;
  }
  .l-pt-0 {
    padding-top: 0rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .half-sidebar-height {
    height: calc(100vh - 196px) !important;
  }
  .sidebar-top-align-view {
    margin-top: 65px !important;
  }
}

.border-color {
  border: solid rgb(165, 159, 159) 1px !important;
  border-radius: 5% !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 10px !important;
  display: inline-block !important;
  border-top: 13px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  content: "";
}
.carousel .control-next.control-arrow:before {
  border-left: 17px solid #fff !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 17px solid #fff !important;
}

.carousel.carousel-slider .control-arrow {
  background: #706c6c !important;
}

@media (min-width: 0px) and (max-width: 767px) {
  // .container-fluid {
  //   padding: 0 !important;
  // }
  // body {
  //   overflow-x: hidden !important;
  // }
  .banner-box .banner-img img {
    height: 130px !important;
  }
  .singer-text {
    padding: 0.8rem !important;
  }
}

.cus-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  gap: 20px 20px;
}
// .grid-items {
//   width: 100%;
//   max-width: 100%;
// }
/* Default styles */
.grid-items {
  width: 100%;
  max-width: 100%;
}

/* Media query for tablets (portrait and landscape) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .grid-items {
    width: 50%; /* Adjust width as needed */
  }
}

/* Media query for phones (portrait) */
@media only screen and (max-width: 767px) {
  .grid-items {
    width: 100%; /* Take full width on smaller screens */
  }
}

@media (min-width: 210px) and (max-width: 767px) {
  .cus-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  .cus-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-items:last-child {
    display: none;
  }
}

.cus-grid-one {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: space-between;
  gap: 20px 20px;
}

@media (min-width: 210px) and (max-width: 767px) {
  .cus-grid-one {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    gap: 20px 20px;
  }
  .font-size-18 {
    font-size: 11px !important;
  }
  .mini-view-p-remove {
    padding: 0.8rem !important;
  }
  .bottom-align-grid {
    margin-bottom: 5rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cus-grid-one {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    gap: 20px 20px;
  }
}

// for banner img size
.banner-box {
  .banner-img {
    img,
    video {
      width: 100%;
      object-fit: cover;
      height: 235px;
      border-radius: 6px 6px 10px 10px;
    }
  }
}
.banner-box {
  img,
  video {
    width: 100%;
    object-fit: cover;
    height: 250px;
    border-radius: 6px 6px 10px 10px;
  }

  .banner-img-mood {
    img,
    video {
      width: 100%;
      object-fit: cover;
      height: 250px;
      border-radius: 6px 6px 10px 10px;
      padding-bottom: 8px;
    }
  }
}

// for banner images scroll
// .singer-img-hover img:hover {
//   transform: scale(1.2);
//   cursor:pointer;
// }
.singer-img-hover img {
  transition: 0.3s;
}

.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .card {
    display: inline-block;
    // @media screen and (max-width: 768px) {
    //   display: block;
    // }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  .singer-img-hover:hover img {
    transform: scale(1.2);
  }
}

.singer-text {
  background-color: #0f0f0f;
  margin-top: -7px;
  box-shadow: 0px 0px 30px #0000004d;
  border-radius: 0px 0px 6px 6px;
  overflow: show;
}

.signup-link {
  color: #1db954;
}

.role-item {
  width: 300px;
  text-align: center;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 15px;
  transition: 500ms;
  &:hover {
    border: 1px solid #1db953;
    color: #1db953;
    transition: 400ms;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .mobile-pb-4 {
    padding-bottom: 1rem;
  }
  .mobile-pb-7{
    padding-bottom: 0;
  }
}

