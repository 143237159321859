.mixer-banner {
    background-image: url("../../../Assets/Images/mixer.png");
    width: 100%;
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.mixer-banner::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.34;
    content: "";
}
.mixer-box {
    height: 100%;
    position: relative;
    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 0px;
    }
}
.mixer-box::before {
    background: #030303;
    opacity: 0.54;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.mixer-text {
    p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 100%;
        cursor: pointer;
    }
}