.shop-banner {
  img {
    height: 450px;
    width: 100%;
  }
  // background-image: url("../../Assets/Images/cloth.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
}
.shop-text {
  height: 450px;
}
.left-right-space {
  padding-left: 100px;
  padding-right: 100px;
}
.cloth-img {
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
        border-radius: 10px;
    }
}
.cloth-profile-after {
    width: 100%;
    border-radius: 10px;
  }

.cloth-profile-after {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-end;
  transform: translateY(100%);
}
.cloth-main-box {
  overflow: hidden;
}
.cloth-main-box:hover .cloth-profile-after {
  transform: translateY(0%);
}

.cloth-after-icon {
  background-color: var(--white);
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.cart-table {
  max-width: calc(100vw - 0px);
  overflow: auto;
  height: calc(100vh - 120px);
}
.cart-table1 td:nth-child(1) {
  width: 180px;
}
.cart-table1 td:nth-child(2) {
  width: 180px;
}
.cart-table1 td:nth-child(3) {
  width: 180px;
}

.cart-table .cart-table1 tr,
.cart-table .cart-table1 td {
  padding: 1rem;
}
.cart-select-img {
  img {
    border-radius: 10px;
  }
}
.cart-select-img {
  img {
    width: 126px;
    height: 122px;
    object-fit: cover;
  }
}
.shop-main-box {
  height: calc(100vh - 78px);
  overflow: auto;
  background-color: #fff;
}

.shopcart-right-banner {
  background: #344746;
}
.product-search {
  input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
  }
  ::placeholder  {
    color: #142524 !important;
    opacity: 0.48 !important;    
  }
}
.apply-btn-product {
  background-color:#1DB954;
  padding: 5px 15px;
  min-height: 38px;
  width: 100%;
  border-radius: 10px;
}
.checkout-btn {
  background-color:#1DB954;
  padding: 5px 15px;
  min-height: 40px;
min-width: 230px;
  border-radius: 10px;
}
.shop-cart-box {
  height: calc(100vh - 90px);
}
.full-height-product {
  height: 100vh;
}
.fix-prduct-box {
  height: calc(100vh - 185px);
  overflow: auto;
  overflow-x: hidden;
}

@media (min-width:210px) and (max-width:767px) {
  .left-right-space {
    padding-left: 0;
    padding-right: 0;
  }
  .m-mb-2 {
    margin-bottom: 1rem !important;
  }
  .m-mt-1 {
    margin-top: 1rem;
  }
  .cus-img {
    margin-top: 1rem;
  }
  .cloth-img img {
    height: 350px;
  }
} 



.signup-modal-grid {
  display: grid;
  grid-template-columns: 90px minmax(0 , 1fr);
  justify-content: space-between;
  gap: 0 20px;
  .signup-modal-grid-items {

  }
}