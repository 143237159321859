input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  background-color: #fff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -4px;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: #1db954;
  height: 4px;
}

.volume-bar input[type="range"]::-webkit-slider-thumb {
  background-color: #fff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -4px;
}

.volume-bar input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ec1c24;

  height: 4px;
}
