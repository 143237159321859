#waves {
  position: relative;
}

#waves span {
  width: 10px;
  height: 5px;
  bottom: 0px;
  position: absolute;
  background: #1db954;
  /* animation: bodong 1.3s infinite ease; */
}

#waves.active span {
  width: 10px;
  height: 5px;
  bottom: 0px;
  position: absolute;
  background: #1db954;
  animation: bodong 1.3s infinite ease;
}
#waves span:first-child {
  left: 0px;
  animation-delay: 0.7s;
}

#waves span:nth-child(2) {
  left: 15px;
  animation-delay: 0.6s;
}
#waves span:nth-child(3) {
  left: 30px;
  animation-delay: 1s;
}
#waves span:nth-child(4) {
  left: 45px;
  animation-delay: 0.5s;
}
#waves span:nth-child(5) {
  left: 60px;
  animation-delay: 1s;
}
#waves span:nth-child(6) {
  left: 75px;
  animation-delay: 0.4s;
}
#waves span:nth-child(7) {
  left: 90px;
  animation-delay: 0.6s;
}
#waves span:nth-child(8) {
  left: 105px;
  animation-delay: 0.4s;
}
#waves span:nth-child(9) {
  left: 120px;
  animation-delay: 0.7s;
}
#waves span:nth-child(10) {
  left: 135px;
  animation-delay: 0.9s;
}

@keyframes bodong {
  0% {
    height: 5px;
    background: bule;
  }
  10% {
    height: 40px;
    background: bule;
  }
  20% {
    height: 20px;
    background: bule;
  }
  30% {
    height: 5px;
    background: bule;
  }
  40% {
    height: 25px;
    background: bule;
  }
  50% {
    height: 15px;
    background: bule;
  }
  60% {
    height: 30px;
    background: bule;
  }
  70% {
    height: 35px;
    background: bule;
  }
  80% {
    height: 10px;
    background: bule;
  }
  90% {
    height: 15px;
    background: bule;
  }
  100% {
    height: 5px;
    background: bule;
  }
}
