.playlist-banner {
  background-image: url("../../../Assets/Images/play.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-height: 350px;
  height: 350px;
}
.playlist-text {
  color: #ffffff;
  text-shadow:  0 1px 4px;
}

.song-text{
  color: #ffffff;
  text-shadow:  0 3px 4px #000;
}
.playlist-banner::before {
  background-color: #c5c5c5;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.72;
}
.sub-playlist {
  width: 260px;
  max-width: 260px;
  img {
    width: 260px;
    height: 213px;
    max-height: 213px;
    object-fit: cover;
    display: flex;
    flex-wrap: wrap;
  }
}
.play-child-image-border-radius {
  border-radius: 32px;
  display: grid;
  flex-wrap: wrap;
}

.dropdown-menu {
  // position: relative; 
  margin-left: -30px;
  display: block;
  z-index: 99999;
}

.widthFull {
  // width: 100vw;
  text-align: center;
}
.comon-btn-color {
  background-color: var(--common-btn-color);
  padding: 8px 15px;
}
.play-all-btn {
  min-height: 38px;
  min-width: 150px;
}
.rounded-btn-style {
  width: 38px;
  height: 38px;
  background-color: var(--common-btn-color);
  border-radius: var(--full-radius);
}
.play-table {
  max-width: calc(100vw - 240px);
  overflow: auto;
  height: calc(100vh - 585px);
}
.play-table .play-table-details th,
.play-table .play-table-details td {
  padding: 0.85rem;
  border-right: 1px solid var(--table-border);
  border-bottom: 1px solid var(--table-border);
}
.play-table-details td:nth-child(1) {
  min-width: 350px;
}
.play-table-details td:nth-child(2) {
  min-width: 350px;
}
.play-table-details td:nth-child(3) {
  min-width: calc(100vw - 965px);
}
.table-heighlite {
  background-color: var(--playlist);
}
.table-header {
  color: #1db954;
}
.play-main-banner {
  img {
    width: 100%;
    height: 578px;
  }
}
.play-table1 {
  max-width: calc(100vw - 0px);
  overflow: auto;
  margin-bottom: 100px;
  // height: calc(100vh - 485px);
}
.play-table1 .play-table-details1 th,
.play-table1 .play-table-details1 td {
  padding: 0.85rem;
  border-right: 1px solid var(--table-border);
  border-bottom: 1px solid var(--table-border);
}

.play-table-details1 tr {
  color: #fff;
}
.play-table-details1 tr:hover {
  background-color: #1c3332;
  // background-color: #ec1c24;
  color: #fff !important;
}
.play-table-details1 td:nth-child(1) {
  min-width: 250px;
}
.play-table-details1 td:nth-child(2) {
  min-width: 250px;
}
.play-table-details1 td:nth-child(3) {
  min-width: 220px;
}
.song-banner img {
  max-width: 170px;
  height: 100px;
  border-radius: 6px;
  object-fit: cover;
}
.create-playlist-center {
  height: calc(100vh - 373px);
}

.mb {
  margin-bottom: -156px;
}
