
.role-item {
    width: 300px;
    text-align: center;
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 15px;
    transition: 500ms;
    &:hover {
      border: 1px solid #1db953;
      color: #1db953;
      transition: 400ms;
      letter-spacing: 2px;
     
    }
  }