.dashboard-banner {
  img {
    
    width: 100%;
    height: 350px;
    border-radius: 7px 7px 7px 7px;
  }
}

.card-holder {
  background-color: var(--singer-box-title-color);
  box-shadow: (0px 0px 30px rgba(0, 0, 0, 0.3));
  border-radius: 7px 7px 7px 7px;
}
.singer-box {
  
  .singer-img {
    img {
      width: 100%;
      object-fit: cover;
      height: 190px;
      border-radius: 6px 6px 10px 10px;
    }
  }
  .singer-text {
    background-color: var(--singer-box-title-color);
    margin-top: -7px;
    box-shadow: (0px 0px 30px rgba(0, 0, 0, 0.3));
    border-radius: 0px 0px 6px 6px;
    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }
}

.fa-ellipsis-v:before {
  padding-top: 26px;
  content: "\f142";
  font-size: 15px !important;
}

//loader
$color-alpha: #fff;
$color-beta: #ec1c24;

.spinner {
  margin: auto;
  width: 60px;
  height: 40px;
}

.spinner > div {
  background: $color-alpha;
  height: 100%;
  width: 5px;
  float: left;
  margin: 0 1px;
  animation: stretch 2s infinite;
  animation-timing-function: cubic-bezier(0.62, 0.28, 0.23, 0.99);
}

.spinner .r1 {
  animation-delay: -1s;
}

.spinner .r2 {
  animation-delay: -0.9s;
}

.spinner .r3 {
  animation-delay: -0.8s;
}

.spinner .r4 {
  animation-delay: -0.7s;
}

.spinner .r5 {
  animation-delay: -0.6s;
}

@keyframes stretch {
  0%,
  20%,
  49% {
    transform: scaleY(0.4);
    background-color: $color-alpha;
  }

  10% {
    transform: scaleY(1);
  }

  50%,
  70%,
  100% {
    transform: scaleY(0.4);
    background-color: $color-beta;
  }

  60% {
    transform: scaleY(1);
    background-color: $color-beta;
  }
}

@media (min-width: 210px) and (max-width: 767px) {
  .singer-img {
    img {
      height: 80px !important;
      border-radius: 4px !important;
    }
  }
}

//less
.dots {
  font-size: 10px;
  position: relative;
  width: 4em;
  height: 1em;
  margin: 10px auto;

  .dot {
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    background: #be411b;
    position: absolute;
    animation: fade 2s ease infinite;
    opacity: 0;

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      left: 1.5em;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      left: 3em;
      animation-delay: 0.5s;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.playlist {
  justify-content: center;
  background-image: url("../../Assets/Images/playlist.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: overlay;
}

.closebtn {
  border-radius: 8px;
}
.button-center-modal {
  position: absolute;
  top: 20px;
  right: 15px;
}
.poppop {
  background-image: url("../../Assets/Images/pay1.jpg");
  width: 100%;
  background-repeat: no-repeat;

  background-size: cover;
  overflow: overlay;
}

.signup-modal-grid {
  display: grid;
  grid-template-columns: 130px minmax(0, 1fr);
  justify-content: space-between;
  gap: 0 20px;
}

.signup-link {
  color: #1db954;
}

.new-musics {
  color: #1db954;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.role-item {
  width: 300px;
  text-align: center;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 15px;
  transition: 500ms;
  &:hover {
    border: 1px solid #1db953;
    color: #1db953;
    transition: 400ms;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .pt-4-mobile {
    padding-top: 0 !important;
  }

  .pl-3-mobile {
    padding-left: 0 !important;
  }
  .pr-3-mobile {
    padding-right: 0 !important;
  }
}

.menu_dd_profile {
  position: relative;
}

.menu_dd {
 position: relative;

  .menu-img {
    position: relative;
  }
  .menu-box {
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 5px;
    position: absolute; /* Absolutely positioned within .menu_dd */
    top: -640%; /* Position the menu-box directly above the menu-img */
    left: -20%; /* Align the menu-box with the left edge of menu-img */
    width: 130px;
    display: none;
    margin-left: -85px;

    z-index: 99999;
  }
   
}
.menu_dd:hover .menu-box {
  display: block; /* Show the menu when hovering over the .menu_dd container */
  
 }
