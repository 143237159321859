@font-face {
  font-family: adobe-font;
  src: url("../../Assets/Font/AdobeFanHeitiStd-Bold.otf");
}

.font-size-40 {
  font-size: 40px;
}
.heading-title-text-color {
  color: var(--heading-title-text-color);
}
::-webkit-input-placeholder {
  color: var(--white) !important;
}

:-ms-input-placeholder {
  color: var(--white) !important;
}

::placeholder {
  color: var(--white) !important;
}
.font-size-20 {
  font-size: 20px;
}
.white-text-color {
  color: var(--white);
}

/* Works on Firefox */
* {
  // scrollbar-width: thin;
  // scrollbar-width: auto;
  // scrollbar-width: thin;
  // scrollbar-width: none;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar  {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #001211;
}

*::-webkit-scrollbar-thumb {
  background-color: #199b46;
  border-radius: 10px;
  border: 1px solid #199b46;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-17 {
  font-size: 17px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}
.font-size-18 {
  font-size: 18px;
}
.font-size-19 {
  font-size: 19px;
}
.font-size-21 {
  font-size: 21px;
}
.font-size-23 {
  font-size: 23px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-25 {
  font-size: 25px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}
.font-size-26 {
  font-size: 26px;
}
.font-size-27 {
  font-size: 27px;
}
.font-size-28 {
  font-size: 28px;
}
.font-size-29 {
  font-size: 29px;
}
.font-size-30 {
  font-size: 30px;
}
.font-size-31 {
  font-size: 31px;
}
.font-size-32 {
  font-size: 32px;
}
.font-size-33 {
  font-size: 33px;
}
.gray-text-color {
  color: var(--gray-text-color);
}
.yellow-icon-color {
  color: var(--yellow-icon-color);
}

.subtitle-text-color {
  color: var(--subtitle-text-color);
}
.font-size-35 {
  font-size: 25px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}
.black-opacity-color {
  color: var(--black-opacity-color);
}
.border-bottom-play {
  border-bottom: 1px solid var(--play-border-color);
}
.link-text-color {
  color: var(--link-text-color);
}
.font-size-30 {
  font-size: 30px;
}
.muted-text-color {
  color: var(--muted);
}
.green-text-color {
  color: var(--green-text-color);
}
.child-text-color {
  color: var(--child-text-color);
}
.shop-now-btn {
  padding: 5px 15px;
  min-width: 150px;
  min-height: 45px;
  text-align: center;
  background-color: var(--shop-btn-background-color);
  border-radius: var(--normal-radius);
}
.black-icon-color {
  color: var(--black-icon-color);
}
.prize-text-color {
  color: var(--prize-text-color);
}
.danger-text-color {
  color: #ff0000;
}
input[type="checkbox"] {
  width: 20px; /*Desired width*/
  height: 20px; /*Desired height*/
}

@keyframes rotate {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  20% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  50% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.animation {
  animation-name: rotate;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  // animation-direction: .3s;
  overflow: hidden;
}
.icon-font-size {
  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
@keyframes round {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(0.3);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
.scale-animation {
  animation-name: round;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}
.adobe-font {
  font-family: adobe-font;
}
.line-nowrap {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.footer-align-space {
  p {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 210px) and (max-width: 767px) {
  .font-size-40 {
    font-size: 20px;
  }
  .font-size-30 {
    font-size: 20px;
  }
  .font-size-20 {
    font-size: 14px;
  }
}

.bottom-align-text {
  margin-top: 150px;
}
.align-content-center {
  img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 768px) {
  .pt-5-mobile {
    padding-top: 0;
  }
  .pt-24-mobile {
    padding-top: 4.5rem;
  }
  .flex-direction-mobile {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
  }
}
