.news-banner {
  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 4px;
  }
}
.heading-profile-img {
  img {
    border-radius: 6px;
    object-fit: cover;
    width: 100%;
    height: 300px;
    max-height: 300px;
  }
}
.reading-img {
  img {
    height: 300px;
    object-fit: cover;
    border-radius: var(--normal-radius);
  }
}
.slider-img {
  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: var(--normal-radius);
  }
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #1c3131, #ccc, #1c3131);
}

.video-grid {
  display: grid;
  grid-template-columns: 500px minmax(0, 1fr);

  gap: 0 60px;
  justify-content: space-between;
  margin-bottom: 5rem;

  @media screen and (max-width :786px) {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .video-grid-items {
    .video-images {
      border-radius: 10px;
      width: 100%;
      height: 250px;
      @media screen and (max-width: 768px) {
        height: auto;
      }

      img {
        border-radius: 10px;
        width: 90%;
        height: 250px;
        object-fit: cover;
        @media screen and (max-width: 768px) {
          width: 100%;
          height: auto;
        }
      }
      video {
        border-radius: 10px;

        height: 250px;
      }
    }
  }
}

.new-fix-width {
  background: #1c3131;
  max-width: 80%;
  margin-top: 6rem;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-top: 5rem;
  }
}

.video-images:nth-child(2) {
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .mobile-pt-7 {
    padding-top: 0;
  }

  .main-box {
    padding-bottom: 80px;
  }
}
