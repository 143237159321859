.profile-show {
  // background-color: #000000;
  height: calc(100vh - 129px);
  overflow-x: hidden;
  overflow: auto;
}
.profile-bottom-border {
  border-bottom: 1px solid #656565;
}
.music-profile-img {
  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media (max-width: 560px) {
    img {
      height: 160px;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      height: 160px;
    }
  }
}
.profile-image {
  .img1 {
    object-fit: cover;
    border-radius: 100%;
  }
}
.music-profile-text {
  background-color: var(--singer-box-title-color);
  box-shadow: (0px 0px 30px rgba(0, 0, 0, 0.3));
  border-radius: 0px 0px 6px 6px;
}
.profile-edit {
  width: 25px;
  height: 25px;
  background-color: #ec1c24;
  border-radius: 50%;
  position: absolute;
  top: 25px;
  right: -10px;
}
.upgrade-btn-style {
  background-color: #1db954;
  padding: 8px 15px;
  min-width: 190px;
  min-height: 40px;
  border-radius: 10px;
}

.profile-text {
  color: #1db954 !important;
}

//edit profile

.avatar-upload {
  position: relative;

  .avatar-edit {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 0;
    input {
      display: none;
      + label {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #920f0f;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(255, 245, 245, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }
        &:after {
          content: "\f040";
          font-family: "FontAwesome";
          color: #757575;
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
        }
      }
    }
  }
}

.follow {
  background-color: #1db954;
  padding: 0px 0px;
  min-width: 100px;
  min-height: 40px;
  border-radius: 10px;
}
.following {
  padding: 0px 0px;
  min-width: 100px;
  min-height: 40px;
  border: solid 2px #1db954;
  border-radius: 10px;
}

@media (min-width: 0px) and (max-width: 767px) {
  .m-pt-2 {
    padding-top: 2rem !important;
    padding-bottom: 6rem !important;
  }
  .profile-show {
    height: auto;
  }
  // .mobile-view-space {
  //   padding:15px;
  // }
  .profile-align-between {
    display: block !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  .profile-music-align {
    padding: 2rem 0 6rem 0rem !important;
  }
  .profile-show {
    height: auto;
  }
  .profile-align-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
}

.main-profile-img {
  overflow: hidden;

  img {
    transition: 0.3s;
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
    @media screen and (max-width: 768px) {
      width: 200px;
      height: 200px;
    }
    &:hover {
      // transform: scale(1.2);
    }
  }
}

.errorlastname {
  color: red;
}

.playlist {
  justify-content: center;
  background-image: url("../../../../src/Assets/Images/playlist.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: overlay;
}

.closebtn {
  border-radius: 8px;
}
.button-center-modal {
  position: absolute;
  top: 20px;
  right: 15px;
}
.poppop {
  background-image: url("../../../../src/Assets/Images/pay1.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: overlay;
}

.signup-modal-grid {
  display: grid;
  grid-template-columns: 90px minmax(0, 1fr);
  justify-content: space-between;
  gap: 0 20px;
  .signup-modal-grid-items {
  }
}

.delete-button {
  border: 1px solid #d6d6d6;
  border-radius: 7px;
  color: #f1f1f1;
  padding: 9px 14px;
  background: #142524;
}
.delete-button:hover {
  color: #f8474d;
  border-radius: 10px;
  transition-duration: 400ms;
  border: 1px solid #ec1c24;
}

@media screen and (max-width : 768px) {
  .profile-align-between {
    margin-bottom: 1rem;
  }
}
.profile-img {
  width: 150px; /* Adjust this value to your desired width */
  height: 150px; /* Optional: maintain aspect ratio or set a specific height */
  border-radius: 50%; /* Optional: make the image circular */
  object-fit: cover; /* Optional: ensure the image fits within the dimensions */
}
.search-bar-container {
  position: relative;
}

