/* Primary Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

em {
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Layout */
.s-layout {
  display: flex;
}

.s-layout__content {
  flex: 1;
}

/* Sidebar */
.s-sidebar__trigger {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4em;
  background: #001211;
}

.s-sidebar__trigger > i {
  display: inline-block;
  margin: 1.5em 0 0 1.5em;
  color: #f07ab0;
}

.s-sidebar__nav {
  position: fixed;
  top: 0;
  left: -15em;
  overflow: auto;
  overflow-x: hidden;
  transition: all 0.3s ease-in;
  width: 14em;

  z-index: 1;
  background: #0f0f0f;
  color: var(--white);
}

.s-sidebar__nav:hover,
.other-sidebar:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0;
}
.other-sidebar:hover,
.other-sidebar:hover,
.other-sidebar:focus,
.s-sidebar__trigger:focus + .other-sidebar,
.s-sidebar__trigger:hover + .other-sidebar {
  left: 0;
}

.s-sidebar__nav ul {
  position: absolute;
  // top: 5em;
  left: 0;
  margin: 0;
  padding: 0;
  width: 14em;
}

.s-sidebar__nav ul li {
  width: 95%;
  margin-left: 5px;
  @media screen and (max-width:768px) {
    font-size: 14px;
  }
}

.s-sidebar__nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3em;
}

.s-sidebar__nav-link em {
  position: absolute;
  top: 50%;
  left: 4em;
  transform: translateY(-50%);
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.s-sidebar__nav-link {
  border: 1px solid transparent;
}
.s-sidebar__nav-link:hover {
  border: 1px solid #414141;
  border-radius: 8px;
  // color: var(--danger);
}

// .s-sidebar__nav-link:hover i {
//   color: var(--danger);
// }

.s-sidebar__nav-link > .sidebar-img {
  //position: absolute;
  top: 0;
  left: 0;
  // display: inline-block;
  width: 3em;
  height: 3em;
  @media screen and (max-width: 768px) {
    height: 2.5rem;
  }
}

.s-sidebar__nav-link > .sidebar-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Mobile First */
@media (min-width: 68em) {
  .s-layout__content {
    margin-left: 4em;
  }

  /* Sidebar */
  .s-sidebar__trigger {
    width: 0em;
  }

  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }

  .s-sidebar__nav:hover,
  .other-sidebar:hover,
  .s-sidebar__nav:focus,
  .s-sidebar__trigger:hover + .s-sidebar__nav,
  .s-sidebar__trigger:focus + .s-sidebar__nav {
    width: 15em;
  }
  .s-sidebar__nav:hover .company-logo {
    display: block !important;
  }
  .s-sidebar__nav:hover .mini-sidebar-show {
    display: none !important;
  }
}
.company-logo {
  display: none;
}
.mini-sidebar-show {
  img {
    transition: 0.2s;
    width: 64px;
    height: auto;
    object-fit: cover;
  }
}
.company-logo {
  img {
    transition: 0.2s;
  }
}

.sidebar-menu-top-align {
  padding-top: 95px;
}
.company-logo {
  img {
    width: 150px;
  }
}
.s-sidebar__nav-link {
  i {
    color: var(--sidebar-icon-color);
  }
}

.full-sidebar-height {
  height: calc(100vh - 0px);
}
.half-sidebar-height {
  height: calc(100vh - 180px);
  z-index: 99;
}
.sidebar-top-align-view {
  margin-top: 78px;
}
.other-sidebar-open {
  position: fixed;
  top: 0;
  overflow: hidden;
  width: 15em;
  transition: all 0.3s ease-in;
  transition: all 0.3s ease-in !important;
  z-index: 1;
  background: #0f0f0f;
  // border-right: 1px solid #707070;
  border: 1px solid #414141;
  color: var(--white);
  height: calc(100vh - 0px) !important;
}

.other-sidebar-open {
  position: fixed;
  top: 0;
  overflow: hidden;
  width: 15em;
  transition: all 0.3s ease-in;
  z-index: 1;
  background: #0f0f0f;
  // border-right: 1px solid #707070;
  border: 1px solid #414141;
  color: var(--white);
  height: calc(100vh - 0px) !important;
}
@media (min-width: 68em) {
  .s-layout__content {
    margin-left: 4em;
  }

  /* Sidebar */
  .s-sidebar__trigger {
    width: 0em;
  }

  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }

  .other-sidebar:hover,
  .other-sidebar:hover,
  .other-sidebar:focus,
  .s-sidebar__trigger:hover + .other-sidebar,
  .s-sidebar__trigger:focus + .other-sidebar {
    width: 15em;
  }
  .other-sidebar:hover .company-logo {
    display: block !important;
  }
  .other-sidebar:hover .mini-sidebar-show {
    display: none !important;
  }
}

.sidebar-img {
  img {
    width: 25px;
    height: auto;
    object-fit: cover;
    @media screen and (max-width: 768px) {
      width: 18px;
    }
  }
}

.mobile-view-show-icon {
  display: none;
}

@media (min-width: 210px) and (max-width: 1024px) {
  .mobile-view-show-icon {
    display: block;
  }
}
@media (min-width: 210px) and (max-height: 767px) {
  .half-sidebar-height {
    height: calc(100vh - 163px);
  }
}
.s-layout__sidebar {
  border: 1px solid #414141;
  border-radius: 9px;
}
.mini-sidebar-text-none {
  opacity: 0;
}
.s-layout__sidebar:hover .mini-sidebar-text-none {
  opacity: 1;
}

.mobile-view-social-media-show {
  display: none;
}
@media (min-width: 0px) and (max-width: 767px) {
  .mobile-view-social-media-show {
    display: block;
  }
  .s-sidebar__nav-link {
    height: 3rem;
    @media screen and (max-width: 768px) {
      height: 2.5rem;
    }
  }
}

@media (min-width: 900px) and (max-width: 1440px) {
  .half-sidebar-height {
    height: calc(100vh - 187px);
    z-index: 99;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .half-sidebar-height {
    height: calc(100vh - 163px);
  }
}

// @media screen and (max-width:768px) {
//   em
// }
