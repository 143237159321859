music-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
music-loader li {
  list-style: none;
  display: inline-block;
  width: 8px;
  height: 40px;
  margin: 0 3px;
  background: #1db954;
  animation: animate 1.5s infinite linear;
}
@keyframes animate {
  0% {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(3);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1);
  }
}
music-loader li:nth-child(1) {
  animation-delay: 0s;
}
music-loader li:nth-child(2) {
  animation-delay: 0.1s;
}
music-loader li:nth-child(3) {
  animation-delay: 0.2s;
}
music-loader li:nth-child(4) {
  animation-delay: 0.3s;
}
music-loader li:nth-child(5) {
  animation-delay: 0.4s;
}
music-loader li:nth-child(6) {
  animation-delay: 0.5s;
}
music-loader li:nth-child(7) {
  animation-delay: 0.6s;
}
music-loader li:nth-child(8) {
  animation-delay: 0.7s;
}
music-loader li:nth-child(9) {
  animation-delay: 0.8s;
}
music-loader li:nth-child(10) {
  animation-delay: 0.9s;
}