header {
  background: #111f1e;
  width: 100%;
  box-shadow: 0px 2px 20px rgba(52, 71, 70, 0.4);
  height: 78px;
  position: fixed;
  top: 0;
  z-index: 20;
}

.header-search {
  background-color: #212e2e;
  color: var(--white);
}

.search-input-width {
  min-width: 400px;
  min-height: 45px;

  @media screen and (max-width: 768px) {
    min-width: 70px;
  }
}

.play-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--play-btn-background-color);
}

.header-profile {
  cursor: pointer;

  img {
    width: 35px;
    height: 35px;
    object-fit: cover;
  }
}

// patiya
.d-none {
  display: none;
}

.hover:hover {
  .d-none {
    display: block;
  }
}

.roundedee {
  border-radius: 40%;
  border: 1px solid #fff;
  height: 50px;
}

.modal-login-box {
  height: calc(90vh - 90px);
}

.modal-singup-box {
  height: calc(90vh - 90px);
}

.modal-login-box {
  background-image: url("../../Assets/Images/login.png");
  width: 100%;
  background-repeat: no-repeat;
  height: calc(90vh - 90px);
  background-size: cover;
  overflow: overlay;
}

.modal-signup-box {
  background-image: url("../../Assets/Images/signup.png");
  width: 100%;
  background-repeat: no-repeat;
  height: calc(90vh - 90px);
  background-size: cover;
  overflow: overlay;
}

.modal-login-box1 {
  background-color: #0f0f0f;
  width: 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 100px);
  background-size: cover;
  overflow: auto;
}

.modal-signup-box1 {
  background-color: #0f0f0f;
  width: 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 100px);
  background-size: cover;
  overflow: auto;
}

.modal-profile-box {
  background-color: #0f0f0f;
  width: 100%;
  background-repeat: no-repeat;
  //height: calc(100vh - 100px);
  background-size: cover;
}

.modal-login-box:before {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  content: "";
}

.modal-signup-box:before {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  content: "";
}

.end-text {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.login-placeholder {
  :-ms-input-placeholder {
    color: #1db954 !important;
  }

  ::placeholder {
    color: #1db954 !important;
  }
}

.form-control-login {
  padding: 20px !important;
  color: #1db954 !important;
  border: 1px solid #fff;
  height: 45px;
  width: 100%;
  border-radius: 50px;
  background-color: transparent !important;
}

.form-control-login-textarea {
  padding: 20px !important;
  color: #1db954 !important;
  border: 1px solid #fff;
  width: 100%;
}

.form-control-login1 {
  padding: 10px 20px !important;
  color: #1db954 !important;
  border-radius: 50px;
  border: 1px solid #fff;
  height: 45px;
  width: 100%;
  background-color: transparent !important;
}

.login-modal-btn {
  min-width: 160px;
  min-height: 45px;
  border: 1px solid #1db954;
  background-color: #1db954;
  padding: 8px 15px;
  border-radius: var(--normal-radius);
}

.signup-modal-btn {
  min-width: 160px;
  min-height: 45px;
  border: 1px solid #1db954;
  background-color: #1db954;
  padding: 8px 15px;
  border-radius: var(--normal-radius);
}

.delete-modal-btn {
  min-width: 160px;
  min-height: 45px;
  border: 1px solid #1db954;
  background: transparent;
  padding: 8px 15px;
  border-radius: var(--normal-radius);
}

.cancel-modal-btn {
  min-width: 160px;
  min-height: 45px;
  border: 1px solid rgb(216, 70, 70);
  background-color: rgb(216, 70, 70);
  padding: 8px 15px;
  border-radius: var(--normal-radius);
}

.login-text-color {
  color: #1db954 !important;
}

.signup-text-color {
  color: #1db954 !important;
}

.footer-background {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(17, 31, 30, 1) 100%);
  position: fixed;
  z-index: 12;
  bottom: 0;
  width: 100%;
  height: 91px;
}

.login-background {
  background-image: url("../../Assets/Images/login-grp.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 250px;
  height: 40px;
}

.signup-background {
  background-image: url("../../Assets/Images/login-grp.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 250px;
  height: 40px;
}

.header-main-title {
  h1 {
    font-size: 28px;
    color: #fff;
  }
}

@media (min-width: 210px) and (max-width: 767px) {
  .footer-background {
    height: auto;
  }
}

@media (min-width: 210px) and (max-width: 1024px) {
  .header-main-logo {
    display: none;
  }
}

.header-main-logo {
  margin-right: 1rem;

  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
}

.mobile-header-flex-align {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    // width: 62%;
  }
}

@media (min-width: 0) and (max-width: 767px) {
  .mobile-header-flex-align {
    display: block;
  }

  .padding-right-remove-header {
    padding-right: 0px !important;
  }

  .mobile-view-social-media-none {
    display: none;
  }
}

.container-fluid-one {
  width: 100%;
  padding-left: 10px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) and (max-width: 1366px) {}

.poppop {
  background-image: url("../../Assets/Images/pay1.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: overlay;
}

.signup-modal-grid {
  display: grid;
  grid-template-columns: 130px minmax(0, 1fr);
  justify-content: space-between;
  gap: 0 30px;

  .signup-modal-grid-items {
    display: null ;
  }
}

@media screen and (max-width: 768px) {
  .dropdownIcon {
    width: 10%;
  }

  .gap-alignment {
    gap: 10px;
  }
}