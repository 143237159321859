@font-face {
  font-family: 'harvestitalregular';
  src: url('../../../Assets/Font/harvestital-regular-webfont.woff2') format('woff2'),
       url('../../../Assets/Font/harvestital-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.service-img {
  background-image: url("../../../Assets/Images/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 350px;
  background-position: center center;
}
.service-img::before {
  background: #142524;
  opacity: 0.84;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
}
.audio-box {
  background-color: var(--audio-box-background-color);
  padding: 25px;
  border-radius: 10px;
}
.service-mixer-img {
  img {
    width: 100%;
    opacity: 0.3;
    border-radius: 10px;
  }
}

select {
  background-color: #000000;
}
option {
  background-color: #000000;
}
.service-mixer-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  //display: flex;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
}
.service-mixer-img :hover {
  opacity: 0.1;
}
.end-text1 {
  padding: 20px;
  justify-content: center;
  align-items: flex-end;
}
.service-child-btn {
  background-color: #1db954;
  border-radius: 6px 6px 0px 0px;
}
.service-information-box {
  background-color: #0f0f0f;
  border-radius: 0px 0px 6px 6px;
}
.service-divider {
  border-top: 1px solid #707070;
}
.servcie-packge-btn-style {
  padding: 8px 45px;
  background-color: #1db954;
  font-size: 16px;
  text-align: center;
  color: #ffff;
}
.service-box-bottom {
  margin-bottom: 1rem;
}

@media (min-width: 0px) and (max-width: 767px) {
  .m-pl-pr-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

.signup-modal-grid {
  display: grid;
  grid-template-columns: 90px minmax(0, 1fr);
  justify-content: space-between;
  gap: 0 20px;
  .signup-modal-grid-items {
  }
}

.spanError {
  color: red;
  font-size: 12px;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 20px;
  padding-bottom: 70px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .service_image {
    display: flex;
    justify-content: center;
    // margin-top: 20px;
  }
  iframe {
    width: 850px;
    height: 500px;
        border-radius: 10px;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 270px;
      margin: 0 10px;
    }
  }
  img {
    padding: 0px 10px 0px 10px;
  }
}
.lh_name {
  display: flex;
  justify-content: center;
  font-size: 56px;
  color: #fff;
  font-weight: 400;
  font-family: "harvestitalregular";
  // font-style: italic;
  @media screen and (max-width: 768px) {
    font-size: 22px;
    text-align: center;
  }
}

.lh_button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  transition: 400ms;
  button {
    color: #fff;
    border: 1px solid #1db953;
    border-radius: 5px;
    transition: 400ms;
    padding: 5px 20px 5px 20px;
    background-color: #1db953;
    @media screen and (max-width: 768px) {
      padding: 3px 15px 3px 15px;
    }
    &:hover {
      letter-spacing: 2px;
      border-radius: 7px;
    }
  }
}
