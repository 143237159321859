.plan-box {
    height:100vh;
    .plan-department {
        background-color: var(--green);
        min-height: 50px;
        border-radius: 10px 10px 0px 0px;
    }
    .child-box-plan {
        background-color:#061E1D;
        box-shadow:(0px 0px 25px rgba(0, 0, 0, 0.47));
        border-radius: 0px 0px 10px 10px;
        .border-right-plan {
            border-right: 1px solid #707070;
        }
    }
    .preminum-btn-style {
        background-color: var(--green);
        padding: 8px 15px;
        min-width: 250px;
        min-height: 50px;
        border-radius: 10px;
    }
}
