:root {
    --primary:#001211;
    --white:#fff;
    --danger:#EC1C24;
    --playlist:#1c3332; 
    --login-page-background-color:var(--primary);
    --normal-radius:10px;
    --sidebar-background-color:#142524;
    --sidebar-icon-color:var(--white);
    --header-bottom-border:#707070;
    --heading-title-text-color:var(--white);
    --singer-box-title-color:#0F0F0F;
    --gray:#A5A5A5;
    --gray-text-color:var(--gray);
    --yellow:#FFBE00;
    --yellow-icon-color:var(--yellow);
    --play-btn-background-color:var(--danger);
    --black:#061E1D;
    --subtitle-text-color:var(--black);
    --black-opacity-color:#3E3E3E;
    --common-btn-color:var(--danger);
    --full-radius:50%;
    --play-border-color:#B2B0B0;
    --audio-box-background-color:var(--black);
    --link-text-color:#0077FF;
    --muted:#7B7878;
    --green:#1DB954;
    --green-text-color:var(--green);
    --child-text-color:#373737;
    --shop-btn-background-color:var(--green);
    --black-icon-color:var(--black);
    --prize-text-color:#373636;
}
