
.cart-final {
    img {
        width: 115px;
        height: 126px;
        border-radius: 10px;
    }
}
.cart-main-img {
    img{
        width: 400px;
        height: 90%;
        object-fit: cover;
        border-radius: 10px;
    }
}
.cus-option-drp {
    background-color: transparent !important;
    color: #fff !important;
    outline: none;
    border: none;
    font-size: 18px;
}
select:focus {
    outline: none !important;
}
select{
    color: #fff !important;
}

.plus-add-btn {
    min-width: 100px;
    min-height: 30px;
    border-radius: 4px;
    background-color: #fff;
    padding: 5px 10px;
    color: #142524;
    font-size:18px;
}
.main-add-to-cart {
    min-width: 161px;
    min-height: 45px;
    background-color: #1DB954;
    border-radius: 10px;
    color: #fff;
}
@media (min-width:0px) and (max-width:767px) {
    .cart-final img {
        height: 80px !important;
        border-radius: 6px;
    }
    .cart-main-img img {
        height: 300px;
    }
    .m-pt-1 {
        padding-top: 1rem;
    }
}



.signup-modal-grid {
    display: grid;
    grid-template-columns: 130px minmax(0 , 1fr);
    justify-content: space-between;
    gap: 0 20px;
    .signup-modal-grid-items {
  
    }
  }